import moment from "moment";
import bookingApi from "@/apis/liff/v2/booking";
import bookingPresetApi from "../../apis/liff/v2/booking-preset";
import _ from "lodash";

const MODULE_CODE = "liff_booking";

export default {
  data() {
    return {
      provider: null,
      presetId: null,
      preset: null,
    };
  },
  computed: {
    fieldSetting() {
      if (this.provider === "linkedcare") {
        return this.getModuleConfig("field_setting");
      } else if (this.preset) {
        let fieldSettings = _.get(this.preset, "config.liff_field_setting");

        if (!fieldSettings || fieldSettings.length === 0) {
          return this.getModuleConfig("field_setting");
        } else {
          return fieldSettings;
        }
      }

      return ;
    },
    bookingStatusMapping() {
      if (this.preset) {
        let statusMapping = _.get(
          this.preset,
          "config.provider_config.booking_status_mapping",
          []
        )

        // if (statusMapping.length > 0) {
        //   return statusMapping.reduce((map, item) => {
        //     map[item.key] = item.title;
        //     return map;
        //   });
        // }

        return statusMapping.find(
          (item) => item.key === this.$route.params.status
        );
      }

      return _.get(
        this.getModuleConfig("provider_config"),
        "booking_status_mapping",
        []
      ).reduce((map, item) => {
        map[item.key] = item.title;
        return map;
      })
    },
    statusFilterMapping() {
      if (this.preset) {
        return _.get(
          this.preset,
          "config.provider_config.status_filter_mapping",
          [
            {
              key: "completed",
              matched_key: ["completed"],
            },
            {
              key: "scheduled",
              matched_key: ["scheduled"],
            },
            {
              key: "cancelled",
              matched_key: ["cancelled"],
            },
          ]
        ).reduce((map, item) => {
          map[item.key] = item.matched_key;
          return map;
        }, {});
      } else {
        return _.get(
          this.getModuleConfig("provider_config"),
          "status_filter_mapping",
          []
        ).reduce((map, item) => {
          map[item.key] = item.title;
          return map;
        }, [])
      }
    },
  },
  methods: {
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"](MODULE_CODE, key);
    },
    init() {
      let filter = this.getModuleConfig("filter_by_date");
      if (filter) {
        if (filter.show_option && !filter.show_calendar) {
          this.daysDiff = filter.options[0].key;
          this.endAt = moment().format("YYYY-MM-DD");

          if (this.$route.query.startAt) {
            this.daysDiff = moment(this.$route.query.endAt).diff(
              this.$route.query.startAt,
              "days"
            );
          }

          this.startAt =
            this.$route.query.startAt ||
            moment().subtract(this.daysDiff, "days").format("YYYY-MM-DD");
        }
        if (filter.show_option) {
          this.endAt = this.$route.query.endAt || moment().format("YYYY-MM-DD");
          this.startAt =
            this.$route.query.startAt ||
            moment()
              .subtract(filter.options[0].key, "days")
              .format("YYYY-MM-DD");

          this.daysDiff =
            this.$route.query.endAt && this.$route.query.startAt
              ? moment(this.$route.query.endAt).diff(
                  this.$route.query.startAt,
                  "days"
                )
              : filter.options[0].key;
        }
      } else {
        this.startAt =
          this.$route.query.startAt ||
          moment().subtract(1, "year").format("YYYY-MM-DD");
        this.endAt =
          this.$route.query.endAt ||
          moment().add(1, "year").format("YYYY-MM-DD");
      }
    },
    async setQuery() {
      const query = {};
      if (
        this.startAt &&
        this.$route.query.startAt &&
        !moment(this.startAt).isSame(this.$route.query.startAt)
      ) {
        query.startAt = this.startAt;
      }
      if (
        this.endAt &&
        this.$route.query.endAt &&
        !moment(this.endAt).isSame(this.$route.query.endAt)
      ) {
        query.endAt = this.endAt;
      }
      if (query.startAt || query.endAt) {
        await this.$router.push({ query });
      }
    },
    async fetchProvider() {
      try {
        const data = await bookingApi.getProvider();
        this.presetId = this.$route.params.presetId || data.preset_id;
        this.provider = data.provider;

        if (!this.provider) {
          this.$swal("錯誤", "尚未設定供應商", "error");
        }

        if (this.provider === "waltily") {
          this.preset = await bookingPresetApi.get(this.presetId);
          if (!this.$route.params.presetId) {
            await this.$router.push({
              name: `${this.$route.name}OfPreset`,
              params: {
                presetId: this.presetId,
              },
            });
          }
        }
      } catch (error) {
        console.info(error);
        this.$swal
          .fire({
            title: `取得預約資料失敗`,
            type: "error",
          })
          .then(() => {
            this.$router.push({ name: "LiffMemberCenterHome" });
          });
      }
    },
  },
};
